import React, { useContext, useEffect } from "react"
import Layout from "components/layout"

import { Button } from "components/anti/buttons/buttons"

import BG4041 from "assets/img/bg_404_1.png"
import BG4042 from "assets/img/bg_404_2.png"
import BG4043 from "assets/img/bg_404_3.png"
import { navigate } from "gatsby-link"
import { Helmet } from "react-helmet"

const NotFoundPage = ({ path }) => {

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Layout path={path}>
        <section className="py-main h-400px content-center sc-notfound">
          <img className="bg-image" src={BG4041} alt="bg image" />
          <img className="bg-image" src={BG4042} alt="bg image" />
          <img className="bg-image" src={BG4043} alt="bg image" />
          <div className="container row">
            <div className="col-md-6 text-md-right text-center">
              <h1 className="text-primary h1">404</h1>
            </div>
            <div className="col-md-6 text-md-left text-center py-4">
              <h4>Looks like you’ve got lost..</h4>
              <p className="text-gray-600 font-weight-light w-300px">The page you’re looking for doesn’t exist or has been moved</p>
              <Button variant="primary" link="/">
                BACK TO HOME
              </Button>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage
